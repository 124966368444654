



export const START_FETCHING_PRODUCTS= 'START_FETCHING_PRODUCTS';
export const FETCHING_PRODUCTS_SUCCESS = 'FETCHING_PRODUCTS_SUCCESS';
export const FETCHING_PRODUCTS_FAILURE = 'FETCHING_PRODUCTS_FAILURE';
export const PRODUCTS_SPINNER_STARTS = 'PRODUCTS_SPINNER_STARTS'
export const PRODUCTS_SPINNER_STOPS = 'PRODUCTS_SPINNER_STOPS'
export const START_FETCHING_PRODUCTS_WITH_SPINNER = 'START_FETCHING_PRODUCTS_WITH_SPINNER'


export const START_FETCHING_PRODUCT_HERO= 'START_FETCHING_PRODUCT_HERO';
export const FETCHING_PRODUCT_HERO_SUCCESS = 'FETCHING_PRODUCT_HERO_SUCCESS';
export const FETCHING_PRODUCT_HERO_FAILURE = 'FETCHING_LOAN_FACTORS_FAILURE';
export const PRODUCT_HERO_SPINNER_STARTS = 'PRODUCT_HERO_SPINNER_STARTS'
export const PRODUCT_HERO_SPINNER_STOPS = 'PRODUCT_HERO_SPINNER_STOPS'
export const START_FETCHING_PRODUCT_HERO_WITH_SPINNER = 'START_FETCHING_PRODUCT_HERO_WITH_SPINNER'

