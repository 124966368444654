import React from "react";
import "./styles.css";
import ClimateChange from "../../assets/images/climateChange.jpeg";
import Esewa from "../../assets/images/esewa.png";
import Khalti from "../../assets/images/khalti.png";
import ImePay from "../../assets/images/imePay.png";
import RedPanda from "../../assets/images/redPanda.jpg";
import LatestRaisers from "../../components/latestFundRaisers/LatestRaisers";
import Startup from "../../assets/images/startup.jpg";
import Business1 from "../../assets/images/business1.png";
import Partnership from "../../assets/images/partnership.png";
import FundCard from "../../components/fundCard/FundCard";
import { useState } from "react";
import StartupForm from "../../components/startupForm/StartupForm";
const ProjectPage = () => {
  const [startupModal, setStartupModal] = useState(false);

  return (
    <div className="project-page-container">
      <div className="container">
        <div className="project-page-inner-container">
          <div className="row" style={{ width: "100%", margin: "auto" }}>
            <div className="col-lg-3 fund-raiser-left">
              <div className="row" style={{ width: "100%", margin: "auto" }}>
                <div className="col-lg-12">
                  <div className="large-device-project-heading">
                    <div className="project-category">startup</div>
                    <div className="project-title col-lg-0 col-xl-0">
                      Startup of an IT Tech
                    </div>
                  </div>
                  <div className="people-supported">
                    15 People have supported our project
                  </div>
                  <div className="progress-container">
                    <div className="funding-collected">
                      <div className="funding-raised-left">
                        Rs. 12000 raised
                      </div>
                      <div className="funding-raised-right">80%</div>
                    </div>
                    <div className="funding-bar">
                      <div
                        className="funding-inner-bar"
                        style={{ background: "#1496d1" }}
                      ></div>
                    </div>
                    <div className="days-left">15 days remains</div>
                  </div>
                  <StartupForm
                    startupModal={startupModal}
                    setStartupModal={setStartupModal}
                  />
                  <div className="support-container">
                    <a
                      href="#"
                      class="btn-flip aos-init aos-animate"
                      data-back="+ SUPPORT"
                      data-front="+ SUPPORT"
                      data-aos="fade-up"
                      onClick={() => setStartupModal(true)}
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 fund-raiser-middle">
              <div className="project-page-image-container">
                <img src={Startup} alt="Startup Fund" />
              </div>
              <div className="col-12">
                <div className="project-page-text">
                  This project aims to plant thousand trees as well as create
                  awareness and motivation for carbon net zero. Lorem Ipsum is
                  simply dummy text of the printing and typesetting
                  industry.Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, This
                  project aims to plant thousand trees as well as create
                  awareness and motivation for carbon net zero. Lorem Ipsum is
                  simply dummy text of the printing and typesetting
                  industry.Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s,
                </div>
              </div>
            </div>
            <div className="col-lg-3 fund-raiser-right">
              <div className="latest-raiser-title">Latest Raisers</div>
              {[
                { title: "Startup of an IT Tech", image: Startup },
                { title: "Need help for survival", image: Business1 },
                {
                  title: "We need partner for our law firm",
                  image: Partnership,
                },
                { title: "Startup of an IT Tech", image: Startup },
              ].map((raiser) => {
                return (
                  <LatestRaisers title={raiser.title} image={raiser.image} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
