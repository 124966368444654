import { Modal, Slider } from "antd";
import React, { useState } from "react";
import "./styles.css";
import Inquiry from "../../assets/images/enquiry.png";
import { useEffect } from "react";

const StartupForm = (props) => {
  const { startupModal, setStartupModal } = props;
  const [inputPhone, setInputPhone] = useState("");
  const [inputName, setInputName] = useState("");
  const [inputAmount, setInputAmount] = useState(0);
  const [inputEquity, setInputEquity] = useState(0);
  const onSliderChange = (e) => {
    setInputEquity(e);
    if (e == 0) {
      setInputAmount(0);
    } else {
      let am = Math.floor((inputEquity / 100) * 100000);
      setInputAmount(am);
    }
  };
  const onInputAmountChanged = (e) => {
    setInputAmount(e);
    if (e == 0 || e == "") {
      setInputEquity(0);
    } else {
      let am = Math.floor((inputAmount / 100000) * 100);
      setInputEquity(am);
    }
  };

  return (
    <Modal
      footer={null}
      header={null}
      title="Basic Modal"
      visible={startupModal}
      onOk={() => setStartupModal(false)}
      destroyOnClose={true}
      wrapClassName="startup-wrap"
      onCancel={() => setStartupModal(false)}
    >
      <div className="startup-container">
        <div className="container">
          <div className="row startup-heading-row">
            <div className="col-5">
              <div className="startup-heading">Startup</div>
              <div className="startup-sub-heading">
                We will respond to you as soon as possible
              </div>
            </div>
            <div className="col-7">
              <div className="startup-image-container">
                <img src={Inquiry} alt="Startup" />
              </div>
            </div>
          </div>
          <div className="startup-input">
            <input
              onChange={(e) => {
                setInputName(e.target.value);
              }}
              type="text"
              name="username"
              id="username"
            />
            <div
              className={`startup-label ${
                inputName.length > 0 ? "startup-label-off" : ""
              }`}
            >
              Name
            </div>
          </div>
          <div className="startup-input">
            <input
              onChange={(e) => {
                setInputPhone(e.target.value);
              }}
              type="text"
              name="userphone"
              id="userphone"
            />
            <div
              className={`startup-label ${
                inputPhone.length > 0 ? "startup-label-off" : ""
              }`}
            >
              Phone
            </div>
          </div>
          <div className="startup-input">
            <input
              onChange={(e) => {
                onInputAmountChanged(e.target.value);
              }}
              type="number"
              name="amount"
              id="amount"
              value={inputAmount}
            />
            <div
              className={`startup-label ${
                inputAmount >= 0 ? "startup-label-off" : ""
              }`}
            >
              Amount
            </div>
          </div>
          <div className="startup-input">
            <div className="equity-heading">Equity</div>
            <Slider
              defaultValue={inputEquity}
              value={inputEquity}
              onChange={(e) => onSliderChange(e)}
            />
          </div>

          <div className="startup-button">
            <button
              class="btn-flip"
              data-back="+ Inquire"
              data-front="+ Inquire"
              style={{ border: "none", padding: "0px", boxShadow: "none" }}
            ></button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StartupForm;
