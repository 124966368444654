import { Modal, Slider } from "antd";
import React, { useState } from "react";
import "./styles.css";
import Inquiry from "../../assets/images/enquiry.png";
import { useEffect } from "react";

const RegisterForm = (props) => {
  const { setUserName, userName, email, setEmail, phone } = props;
  const [inputPhone, setInputPhone] = useState("");
  const [inputName, setInputName] = useState("");

  return (
    <div className="startup-container">
      <div className="startup-input">
        <input
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          type="text"
          name="username"
          id="username"
          value={userName}
        />
        <div
          className={`startup-label ${
            userName.length > 0 ? "startup-label-off" : ""
          }`}
        >
          Name
        </div>
      </div>
      <div className="startup-input">
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="text"
          name="email"
          id="email"
          value={email}
        />
        <div
          className={`startup-label ${
            email.length > 0 ? "startup-label-off" : ""
          }`}
        >
          Email
        </div>
      </div>

      <div className="startup-input">
        <input
          // onChange={(e) => {
          //   setInputPhone(e.target.value);
          // }}
          type="text"
          name="userphone"
          id="userphone"
          value={phone}
          fixed
        />
        <div
          className={`startup-label ${
            phone.length > 0 ? "startup-label-off" : ""
          }`}
        >
          Phone
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
