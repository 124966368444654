export const START_FETCHING_LANDING_HERO = "START_FETCHING_LANDING_HERO";
export const FETCHING_LANDING_HERO_SUCCESS = "FETCHING_LANDING_HERO_SUCCESS";
export const FETCHING_LANDING_HERO_FAILURE = "FETCHING_LANDING_HERO_FAILURE";
export const LANDING_HERO_SPINNER_STARTS = "LANDING_HERO_SPINNER_STARTS";
export const LANDING_HERO_SPINNER_STOPS = "LANDING_HERO_SPINNER_STOPS";
export const START_FETCHING_LANDING_HERO_WITH_SPINNER =
  "START_FETCHING_LANDING_HERO_WITH_SPINNER";

export const START_FETCHING_LANDING_ABOUT = "START_FETCHING_LANDING_ABOUT";
export const FETCHING_LANDING_ABOUT_SUCCESS = "FETCHING_LANDING_ABOUT_SUCCESS";
export const FETCHING_LANDING_ABOUT_FAILURE = "FETCHING_LOAN_FACTORS_FAILURE";
export const LANDING_ABOUT_SPINNER_STARTS = "LANDING_ABOUT_SPINNER_STARTS";
export const LANDING_ABOUT_SPINNER_STOPS = "LANDING_ABOUT_SPINNER_STOPS";
export const START_FETCHING_LANDING_ABOUT_WITH_SPINNER =
  "START_FETCHING_LANDING_ABOUT_WITH_SPINNER";

export const START_FETCHING_LANDING_SERVICE = "START_FETCHING_LANDING_SERVICE";
export const FETCHING_LANDING_SERVICE_SUCCESS =
  "FETCHING_LANDING_SERVICE_SUCCESS";
export const FETCHING_LANDING_SERVICE_FAILURE =
  "FETCHING_LANDING_SERVICE_FAILURE";
export const LANDING_SERVICE_SPINNER_STARTS = "LANDING_SERVICE_SPINNER_STARTS";
export const LANDING_SERVICE_SPINNER_STOPS = "LANDING_SERVICE_SPINNER_STOPS";
export const START_FETCHING_LANDING_SERVICE_WITH_SPINNER =
  "START_FETCHING_LANDING_SERVICE_WITH_SPINNER";

export const START_FETCHING_TEAM = "START_FETCHING_TEAM";
export const FETCHING_TEAM_SUCCESS = "FETCHING_TEAM_SUCCESS";
export const FETCHING_TEAM_FAILURE = "FETCHING_TEAM_FAILURE";
export const TEAM_SPINNER_STARTS = "TEAM_SPINNER_STARTS";
export const TEAM_SPINNER_STOPS = "TEAM_SPINNER_STOPS";
export const START_FETCHING_TEAM_WITH_SPINNER =
  "START_FETCHING_TEAM_WITH_SPINNER";

export const START_FETCHING_TESTIMONIAL = "START_FETCHING_TESTIMONIAL";
export const FETCHING_TESTIMONIAL_SUCCESS = "FETCHING_TESTIMONIAL_SUCCESS";
export const FETCHING_TESTIMONIAL_FAILURE = "FETCHING_TESTIMONIAL_FAILURE";
export const TESTIMONIAL_SPINNER_STARTS = "TESTIMONIAL_SPINNER_STARTS";
export const TESTIMONIAL_SPINNER_STOPS = "TESTIMONIAL_SPINNER_STOPS";
export const START_FETCHING_TESTIMONIAL_WITH_SPINNER =
  "START_FETCHING_TESTIMONIAL_WITH_SPINNER";
