import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import "./styles.css";
import { message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  cloaseLoginModal,
  closeLoginModal,
  triggerModal,
} from "../../store/layoutStore/layoutStore.actions";
import Logo from "../../assets/images/logo.png";
import LoginImage from "../../assets/images/login.png";
import { useState } from "react";
import PreLoader from "../preLoader/PreLoader";
import { server } from "../../utils/fetch";
import OtpInput from "react-otp-input";
import RegisterForm from "../RegisterForm/StartupForm";
// import Typing from 'react-typing-animation';
const ScrollToTop = (props) => {
  const loginModal = useSelector((state) => state.layoutStore.loginModal);
  const dispatch = useDispatch();
  const [login, setLogin] = useState(true);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");

  const [reqSuccess, setReqSuccess] = useState(false);
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const [reqData, setReqData] = useState(null);
  const [needRegister, setNeedRegister] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const requestOTP = async () => {
    if (phone) {
      try {
        console.log(phone);
        let newPhone = JSON.stringify(phone);

        const res = await fetch(`${server}/auth/requestOtp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone }),
        });
        if (res.status == 200) {
          setReqSuccess(true);
        }
        const data = await res.json();
        setReqData(data);
      } catch (error) {
        console.log("Request OTP failed: ", error);
      }
    } else {
      return message.error("Please enter your phone number");
    }
  };

  const otpFilled = async () => {
    if (otp.length == 6) {
      try {
        console.log(phone);
        let newPhone = JSON.stringify(phone);

        const res = await fetch(`${server}/auth/verifyOtp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: reqData.phone,
            otp,
            hash: reqData.hash,
          }),
        });
        if (res.status == 200) {
          setNeedRegister(true);
        }
        const data = await res.json();
        if (data.user) {
        } else {
          setNeedRegister(true);
        }
        console.log(data);
      } catch (error) {
        console.log("Verify OTP failed: ", error);
      }
    } else {
      return message.error("Please enter otp");
    }
  };

  const onRegister = async () => {
    if (email && userName && reqData && reqData.phone) {
      try {
        console.log(phone);
        let newPhone = JSON.stringify(phone);

        const res = await fetch(`${server}/auth/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: reqData.phone,
            email,
            role: "novice",
            username: userName,
            password: "password",
          }),
        });
        if (res.status == 201) {
          const res = await fetch(`${server}/auth/me`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          const data = await res.json();
          console.log(data);
        }
        const data = await res.json();
        if (data.user) {
        } else {
        }
        console.log(data);
      } catch (error) {
        console.log("Register failed: ", error);
      }
    } else {
      return message.error("Please enter required values");
    }
  };
  const handleOtpChange = (e) => {
    console.log(otp.length);
    setOtp(e);
  };

  console.log(reqData);
  return (
    <>
      <div className="main-child-container">{props.children}</div>

      <PreLoader />

      {/* <div class='cursor' id="cursor"></div> */}
      <Modal
        footer={null}
        header={null}
        title="Basic Modal"
        visible={loginModal}
        onOk={() => {
          setReqSuccess(false);
          dispatch(closeLoginModal());
        }}
        destroyOnClose={true}
        wrapClassName="login-wrap"
        onCancel={() => {
          setReqSuccess(false);
          dispatch(closeLoginModal());
        }}
      >
        <div className="login-container">
          <div className="container">
            <div className="modal-logo">
              <img src={Logo} alt="logo" />
            </div>
            <div className="login-info">
              <div className="login-image">
                <img src={LoginImage} alt="Login" />
              </div>
              <div className="login-text">
                To {login ? "Login" : "Register"},<br />
                {/* <Typing> */}
                Please enter your mobile number
                {/* </Typing> */}
              </div>
            </div>

            {!needRegister ? (
              <>
                {!reqSuccess ? (
                  <>
                    <div className="mobile-number-container">
                      <div className="mobile-input-container">
                        <div className="mobile-selection">+977</div>
                        <div className="mobile-input">
                          <input
                            type="text"
                            name="pnone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            id="phone"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="otp-button">
                      <button onClick={() => requestOTP()}>
                        Login with OTP
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="enter-otp">Enter OTP</div>
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={6}
                      inputStyle={{
                        height: "40px",
                        width: "40px",
                      }}
                      containerStyle={{
                        marginBottom: "30px",
                        justifyContent: "center",
                      }}
                      separator={<span>-</span>}
                    />
                    <div className="otp-button">
                      <button onClick={() => otpFilled()}>Proceed</button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <RegisterForm
                  email={email}
                  userName={userName}
                  setUserName={setUserName}
                  setEmail={setEmail}
                  phone={reqData?.phone}
                />
                <div className="otp-button">
                  <button onClick={() => onRegister()}>Register</button>
                </div>
              </>
            )}
            {login ? (
              <div className="first-tim">
                First time user?{" "}
                <span className="register" onClick={() => setLogin(false)}>
                  Register
                </span>
              </div>
            ) : (
              <div className="first-tim">
                Already registered?{" "}
                <span className="register" onClick={() => setLogin(true)}>
                  Login
                </span>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScrollToTop;
