



export const START_FETCHING_FUND_CATEGORY = 'START_FETCHING_FUND_CATEGORY';
export const FETCHING_FUND_CATEGORY_SUCCESS = 'FETCHING_FUND_CATEGORY_SUCCESS';
export const FETCHING_FUND_CATEGORY_FAILURE = 'FETCHING_FUND_CATEGORY_FAILURE';

export const FUND_CATEGORY_SPINNER_STARTS = 'FUND_CATEGORY_SPINNER_STARTS'
export const FUND_CATEGORY_SPINNER_STOPS = 'FUND_CATEGORY_SPINNER_STOPS'
export const START_FETCHING_FUND_CATEGORY_WITH_SPINNER = 'START_FETCHING_FUND_CATEGORY_WITH_SPINNER'