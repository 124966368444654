



export const START_FETCHING_SERVICE = 'START_FETCHING_SERVICE';
export const FETCHING_SERVICE_SUCCESS = 'FETCHING_SERVICE_SUCCESS';
export const FETCHING_SERVICE_FAILURE = 'FETCHING_SERVICE_FAILURE';

export const SERVICE_SPINNER_STARTS = 'SERVICE_SPINNER_STARTS'
export const SERVICE_SPINNER_STOPS = 'SERVICE_SPINNER_STOPS'
export const START_FETCHING_SERVICE_WITH_SPINNER = 'START_FETCHING_SERVICE_WITH_SPINNER'