



export const START_FETCHING_LOAN_WHATIS= 'START_FETCHING_LOAN_WHATIS';
export const FETCHING_LOAN_WHATIS_SUCCESS = 'FETCHING_LOAN_WHATIS_SUCCESS';
export const FETCHING_LOAN_WHATIS_FAILURE = 'FETCHING_LOAN_WHATIS_FAILURE';
export const LOAN_WHATIS_SPINNER_STARTS = 'LOAN_WHATIS_SPINNER_STARTS'
export const LOAN_WHATIS_SPINNER_STOPS = 'LOAN_WHATIS_SPINNER_STOPS'
export const START_FETCHING_LOAN_WHATIS_WITH_SPINNER = 'START_FETCHING_LOAN_WHATIS_WITH_SPINNER'


export const START_FETCHING_LOAN_FACTORS= 'START_FETCHING_LOAN_FACTORS';
export const FETCHING_LOAN_FACTORS_SUCCESS = 'FETCHING_LOAN_FACTORS_SUCCESS';
export const FETCHING_LOAN_FACTORS_FAILURE = 'FETCHING_LOAN_FACTORS_FAILURE';
export const LOAN_FACTORS_SPINNER_STARTS = 'LOAN_FACTORS_SPINNER_STARTS'
export const LOAN_FACTORS_SPINNER_STOPS = 'LOAN_FACTORS_SPINNER_STOPS'
export const START_FETCHING_LOAN_FACTORS_WITH_SPINNER = 'START_FETCHING_LOAN_FACTORS_WITH_SPINNER'


export const START_FETCHING_LOAN_ELIGIBILITY= 'START_FETCHING_LOAN_ELIGIBILITY';
export const FETCHING_LOAN_ELIGIBILITY_SUCCESS = 'FETCHING_LOAN_ELIGIBILITY_SUCCESS';
export const FETCHING_LOAN_ELIGIBILITY_FAILURE = 'FETCHING_LOAN_ELIGIBILITY_FAILURE';
export const LOAN_ELIGIBILITY_SPINNER_STARTS = 'LOAN_ELIGIBILITY_SPINNER_STARTS'
export const LOAN_ELIGIBILITY_SPINNER_STOPS = 'LOAN_ELIGIBILITY_SPINNER_STOPS'
export const START_FETCHING_LOAN_ELIGIBILITY_WITH_SPINNER = 'START_FETCHING_LOAN_ELIGIBILITY_WITH_SPINNER'



export const START_FETCHING_LOAN_TYPE= 'START_FETCHING_LOAN_TYPE';
export const FETCHING_LOAN_TYPE_SUCCESS = 'FETCHING_LOAN_TYPE_SUCCESS';
export const FETCHING_LOAN_TYPE_FAILURE = 'FETCHING_LOAN_TYPE_FAILURE';
export const LOAN_TYPE_SPINNER_STARTS = 'LOAN_TYPE_SPINNER_STARTS'
export const LOAN_TYPE_SPINNER_STOPS = 'LOAN_TYPE_SPINNER_STOPS'
export const START_FETCHING_LOAN_TYPE_WITH_SPINNER = 'START_FETCHING_LOAN_TYPE_WITH_SPINNER'