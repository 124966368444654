import React from "react";
import './styles.css'
const FundCard = (props) => {
  const { title, image } = props;
  return (
    <div className="fund-raiser-card">
      <div className="fund-icon">
        <img src={image} alt="Fund Request" />
      </div>
      <div className="fund-title">{title}</div>
    </div>
  );
};

export default FundCard;
