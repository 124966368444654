export const TRIGGER_SLIDER_COLLAPSE = "TRIGGER_SIDER_COLLAPSE";

export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";

export const TRIGGER_SLIDER_MOBILE_COLLAPSE = "TRIGGER_SLIDER_MOBILE_COLLAPSE";



export const START_FETCHING_SERVICE_CATEGORY = 'START_FETCHING_SERVICE_CATEGORY';
export const FETCHING_SERVICE_CATEGORY_SUCCESS = 'FETCHING_SERVICE_CATEGORY_SUCCESS';
export const FETCHING_SERVICE_CATEGORY_FAILURE = 'FETCHING_SERVICE_CATEGORY_FAILURE';

export const SERVICE_CATEGORY_SPINNER_STARTS = 'SERVICE_CATEGORY_SPINNER_STARTS'
export const SERVICE_CATEGORY_SPINNER_STOPS = 'SERVICE_CATEGORY_SPINNER_STOPS'
export const START_FETCHING_SERVICE_CATEGORY_WITH_SPINNER = 'START_FETCHING_SERVICE_CATEGORY_WITH_SPINNER'