import React from "react";
import { Link } from "react-router-dom";
import ClimateChange from "../../assets/images/climateChange.jpeg";
import { imageServer } from "../../utils/fetch";
import "./styles.css";
function LatestRaisers(props) {
  const { image, link, title, route } = props;
  return (
    <div className="latest-raisers-box">
      <div className="latest-raisers-image">
        <img src={image ? image : `${imageServer}/${link}`} alt="Raisers" />
      </div>
      <div className="latest-raisers-text">
        <Link to={route? route : `/fund-raiser/project-page`} className="latest-raiser-heading">{title}</Link>
        <div className="fund-raiser-detail">
          This project aims to plant thousand trees as well as create awareness
          and motivation for carbon net zero.
        </div>
      </div>
    </div>
  );
}

export default LatestRaisers;
