



export const START_FETCHING_FUND_RAISER = 'START_FETCHING_FUND_RAISER';
export const FETCHING_FUND_SUCCESS = 'FETCHING_FUND_SUCCESS';
export const FETCHING_FUND_FAILURE = 'FETCHING_FUND_FAILURE';

export const FUND_SPINNER_STARTS = 'FUND_SPINNER_STARTS'
export const FUND_SPINNER_STOPS = 'FUND_SPINNER_STOPS'
export const START_FETCHING_FUND_RAISER_WITH_SPINNER = 'START_FETCHING_FUND_RAISER_WITH_SPINNER'