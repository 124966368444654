import React from "react";
import "./styles.css";
import FundRaiserBox from "../../components/fundRaiserBox/FundRaiserBox";
import RedPanda from "../../assets/images/redPanda.jpg";
import ClimateChange from "../../assets/images/climateChange.jpeg";
import EarthGone from "../../assets/images/earthGone.jpeg";
import Electricity from "../../assets/images/electricity.jpg";
import SpaceImage from "../../assets/images/space.jpg";
import StartupVector from "../../assets/images/startupVector.png";
import BusinessVector from "../../assets/images/businessVector.png";
import PartnerVector from "../../assets/images/partnerVector.png";
import Startup1 from "../../assets/images/startup1.png";
import Startup2 from "../../assets/images/startup2.png";
import Startup from "../../assets/images/startup.jpg";

import Business1 from "../../assets/images/business1.png";
import Partnership from "../../assets/images/partnership.png";
import FundCard from "../../components/fundCard/FundCard";

const FundRaiserPage = () => {
  return (
    <div className="fund-raiser-page-container">
      <div className="container">
        <div className="fund-raiser-page-inner-container">
          <div className="fund-raiser-card-container">
            <div className="row" style={{width:"100%"}}>
              <div
                className="col-lg-3 mb-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="fund-top-title">Make your impact</div>
                <div className="fund-card-title">Fundraise for...</div>
              </div>
              <div className="col-lg-3 mb-4">
                <FundCard image={StartupVector} title="Startup" />
              </div>
              <div className="col-lg-3 mb-4">
                <FundCard image={PartnerVector} title="Partnership" />
              </div>
              <div className="col-lg-3 mb-4">
                <FundCard image={BusinessVector} title="Existing Business" />
              </div>
            </div>
          </div>
          {/* <div className="featured-project">
            <div className="fund-raiser-heading col-md-12">
              <div className="fund-raiser-header">Fund Our Projects</div>
            </div>
            <div className="row">
              <div className="col-md-6 order-md-2">
                <div className="featured-media">
                  <Link to="/fund-raiser/project-page">
                    <img src={RedPanda} alt="Red Panda" />
                  </Link>
                </div>
              </div>
              <div
                className="col-md-6 order-md-1"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="featured-detail">
                  <div className="fund-raiser-description">
                    <div className="fund-raiser-type">Featured Environment</div>
                    <div className="fund-raiser-title">
                      Fund raiser for climate change
                    </div>
                    <div className="fund-raiser-info">
                      This project aims to plant thousand trees as well as
                      create awareness and motivation for carbon net zero.
                    </div>
                  </div>
                  <div className="funding-section">
                    <div className="funding-collected">
                      <div className="funding-raised-left">
                        Rs. 12000 raised
                      </div>
                      <div className="funding-raised-right">80%</div>
                    </div>
                    <div className="funding-bar">
                      <div className="funding-inner-bar"></div>
                    </div>
                  </div>
                  <div className="days-left">15 days remains</div>
                  <a href="#"></a>
                  <Link
                    to="/fund-raiser/project-page"
                    class="btn-flip aos-init aos-animate"
                    data-back="+ LEARN MORE"
                    data-front="+ LEARN MORE"
                    data-aos="fade-up"
                    style={{ marginTop: "15px" }}
                  />
                </div>
              </div>
            </div>
          </div> */}

          <div className="fund-raiser-body">
            <div className="latest-projects-header">Latest Fund Raisers</div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-lg-3 col-12 col-xl-3 fund-raiser-margin">
                <FundRaiserBox
                  title="Startup of an IT Tech"
                  category="startup"
                  image={Startup}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-lg-3 col-12 col-xl-3 fund-raiser-margin">
                <FundRaiserBox
                  title="Need help for survival"
                  category="Existing Business"
                  image={Business1}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-lg-3 col-12 col-xl-3 fund-raiser-margin">
                <FundRaiserBox
                  title="We need partner for our law firm"
                  category="Partnership"
                  image={Partnership}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-lg-3 col-12 col-xl-3 fund-raiser-margin">
                <FundRaiserBox
                  title="Startup of an IT Tech"
                  category="startup"
                  image={Startup}
                />
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundRaiserPage;
